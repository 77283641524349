let common = {
  // 工单信息
  first: [
    { label: '工单信息', isTitle: true, col: 24 },
    { label: '工单号', key: 'workOrderCode', },
    { label: '工单类型', key: 'repairTypeValue', },
  ],
   // 审核信息
   checkInfo: [
    { label: '审核信息', isTitle: true, col: 24 },
    { label: '审核状态', key: 'approvedResultStatus', },
    { label: '审核人', key: 'approvedResultEmployee', },
    { label: '审核时间', key: 'approvedResultTime', },
    { label: '审核说明', key: 'approvedResultResult', },
  ],
  // 提单信息
  submitInfo: [
    { label: '提单信息', isTitle: true, col: 24 },
    { key: 'submitEmployeeName', label: '提单人', },
    { key: 'submitEmployeeCompany', label: '所属公司', },
    { key: 'submitEmployeeMobile', label: '联系电话', },
    { key: 'orderSubmitTime', label: '提单时间', },
    { key: 'solveLimitTime', label: '处理时限', rightText: '分钟' },
  ],
  // 接单信息
  receiveInfo: [
    { key: 'title', label: '接单信息', isTitle: true, col: 24 },
    { key: 'statusValue', label: '工单状态', },
    { key: 'dispatchName', label: '派单人', },
    { key: 'takingName', label: '接单人', },
    { key: 'takingDeptName', label: '所属部门', },
  ],
  // 工单操作记录
  orderRecord: [
    { key: 'title', label: '工单操作记录', isTitle: true, col: 24 },
    {
      type: 'table', label: '工单操作记录', noSearch: true, showPage: false, key: 'flowListVos', col: 24, table_MaxHeight: 500,
      columns: [
        { label: '时间  ', prop: 'createTime', width: 150 },
        { label: '人员', prop: 'operationEmployeeName', width: 100 },
        { label: '操作处理', prop: 'dealText' },
      ]
    },
  ]
}

export default {
  // 维修
  repair: [
    ...common.first,
    { label: '报修类别', key: 'workOrderCategoryValue', },
    { label: '报修类型', key: 'repairTypeValue', },
    { label: '所在楼栋', key: 'buildingName', },
    { label: '所在楼层', key: 'floorName', },
    { label: '详细位置', key: 'workOrderAddress', },
    { label: '报修数量', key: 'repairNumber', },
    { label: '报修描述', key: 'workOrderDescription', },
    { type: 'customize', customize: 'uplpadfaceImg', label: '现场图片', key: 'submitImgs', col: 24, alignItems: 'flex-start' }, //attachmentList
    ...common.submitInfo,
    ...common.receiveInfo,
    { label: '维修描述', key: 'solveDescription', col: 22 },
    { type: 'customize', customize: 'repairImg', label: '维修拍照', key: 'solveImgs', col: 24, alignItems: 'flex-start' },
    ...common.orderRecord
  ],

  // 维保
  inspection: [
    ...common.first,
    { label: '设备类型', key: 'repairTypeValue', },
    { label: '计划类型', key: 'planTypeValue', },
    ...common.submitInfo,
    { label: '维保清单', isTitle: true, col: 24 },
    { type: 'customize', customize: 'planDetailVos', key: 'planDetailVos', col: 24, label_width: 0 },
    ...common.receiveInfo,
    ...common.checkInfo,
    ...common.orderRecord
  ],

  // 告警
  alarm: [
    ...common.first,
    { label: '告警类型', key: 'serviceName', },
    { label: '告警设备', key: 'serviceName', },
    { label: '告警时间', key: 'serviceName', },
    { label: '告警位置', key: 'serviceName', },
    { label: '告警描述', key: 'serviceName', },
    { type: 'customize', customize: 'uplpadfaceImg', label: '告警图片', key: 'urlLists', col: 24, alignItems: 'flex-start' }, //attachmentList
    ...common.submitInfo,
    ...common.receiveInfo,
    { type: 'customize', customize: 'repairImg', label: '处理拍照', key: 'urlLists', col: 24, alignItems: 'flex-start' },
    { label: '处理描述', key: 'serviceName', },
    ...common.orderRecord
  ],
  // 巡更
  scan: [
    ...common.first,
    { label: '巡更路线', key: 'patrolPathName', },
    { label: '执行频率', key: 'patrolFrequency', },
    { label: '线路描述', key: 'patrolPathDesc', },
    ...common.submitInfo,
    { label: '维保清单', isTitle: true, col: 24 },
    { type: 'customize', customize: 'planDetailVos', key: 'planDetailVos', col: 24, label_width: 0 },
    ...common.receiveInfo,
    ...common.checkInfo,
    ...common.orderRecord
  ],
}
